<template>
  <b-card
      v-if="post"
      class="post-edit-wrapper"
  >
    <b-modal id="confirm-delete-post" ref="ref-confirm-delete-post" title="Удалить пост?" hide-footer>
      <p class="my-4">Вы уверены что хотите удалить пост?</p>
      <div class="d-flex flex-row col-12 justify-content-between">
        <b-button variant="danger" @click="deletePost">Да</b-button>
        <b-button variant="outline-secondary" @click="hideModal">Нет</b-button>
      </div>
    </b-modal>

    <!-- Автор -->
    <b-media
        no-body
        vertical-align="center"
    >
      <b-media-aside class="mr-75">
        <b-avatar
            size="38"
            :src="post.author.avatar"
        />
      </b-media-aside>
      <b-media-body>
        <h6 class="mb-25">
          {{ post.author.fullName }}
        </h6>
        <b-card-text>{{ new Date() | moment("DD.MM.YYYY HH:MM") }}</b-card-text>
      </b-media-body>
    </b-media>

    <!-- Форма -->
    <validation-observer v-slot="{invalid}">
      <b-form class="mt-2">
        <b-row>
          <b-col md="6">
            <BlInput rules="required"
                     label="Заголовок"
                     placeholder="Новые светильники!"
                     v-model="post.title"
                     name="title"></BlInput>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="Категории"
                label-for="post-edit-category"
                class="mb-2"
            >
              <v-select
                  id="post-category"
                  v-model="post.categories"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="sidebar.categories"
                  label="title"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <BlInput name="slug"
                     rules="required|min:8"
                     placeholder="new-light"
                     v-model="post.slug"
                     label="URL"></BlInput>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="Статус"
                label-for="post-edit-category"
                class="mb-2"
            >
              <v-select
                  id="post-edit-category"
                  v-model="post.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOption"
                  :clearable="false"
                  value="value"
                  label="title"
              />
            </b-form-group>
          </b-col>

          <!-- Текст статьи -->
          <b-col cols="12">
            <b-form-group
                label="Контент"
                label-for="post-content"
                class="mb-2"
            >
              <quill-editor
                  id="post-content"
                  v-model="post.text"
                  :options="snowOption"
              />
            </b-form-group>
          </b-col>

          <!-- Карточка картинки -->
          <b-col
              cols="12"
              class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Главная картинка статьи
              </h4>
              <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                      v-if="!postFile"
                      ref="refPreviewEl"
                      :src="post.picture"
                      height="110"
                      class="rounded mr-2 mb-1 mb-md-0"
                  />
                  <b-img
                      v-if="postFile"
                      ref="refPreviewEl"
                      :src="image"
                      height="110"
                      class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <h4>Главная картинка</h4>
                  <small class="text-muted">Необходимое разрешение 800x400, размер до 1Mb.</small>
                  <b-card-text class="my-50">
                  </b-card-text>
                  <div class="d-inline-block">
                    <b-form-file
                        ref="refInputEl"
                        v-model="postFile"
                        accept=".jpg, .png, .gif"
                        placeholder="Выберите файл"
                        browseText="Открыть"
                        @input="onFileChange"
                    />
                    <b-button variant="outline-danger mt-2" @click="resetImage"> Сбросить картинку</b-button>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <!-- Кнопки -->
          <b-col
              cols="12"
              class="mt-50 ml-2 justify-content-between row"
          >
            <div class="">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="onUpdate"
                  :disabled="invalid"
              >
                Сохранить
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="clearPost"
              >
                Отмена
              </b-button>
            </div>
            <div class="">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-1"
                  v-b-modal.confirm-delete-post
              >
                Удалить пост
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {quillEditor} from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import '@/libs/custom-validate'
import BlInput from "@/components/controls/BlInput";
import api from "@/api";
import utils from "@/utils";
import {ValidationProvider, ValidationObserver} from 'vee-validate'

export default {
  components: {
    BModal,
    BlInput,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormFile,
    vSelect,
    quillEditor,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    getPost(id) {
      let self = this
      api.posts.getPostById(id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              this.post = {}
              throw "Ошибка";
            } else {
              this.post = data.result
              if (!this.post.picture){
                this.post.picture = "/static/images/no_photo.png"
              }
              this.post.status = this.startStatus
              this.id = this.post.id
              this.post.author.fullName = `${this.post.author.last_name} ${this.post.author.first_name} ${this.post.author.middle_name}`
              this.tempPost = this.post
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    getCategories() {
      const self = this;
      api.posts
          .getCategories()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.sidebar.categories = data.result
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },

    sendPost() {
      let fd = new FormData();
      let categoriesId = []
      if (this.post.text === undefined) {
        fd.append('text', '');
      } else {
        fd.append('text', this.post.text);
      }
      for (let category in this.post.categories) {
        categoriesId.push(this.post.categories[category].id)
        fd.append('categories', categoriesId[category]);
      }
      fd.append('title', this.post.title);
      fd.append('slug', this.post.slug);
      fd.append('status', this.post.status.value);
      fd.append('picture', this.pathImage);
      api.posts
          .updatePost(this.id, fd)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              utils.notificate(this, data.message);
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
      this.$router.go(-2)
    },
    onUpdate(){
      const self = this;
      if (self.postFile) {
        api.files
            .upload(this.postFile)
            .then((response) => {
              const data = response.data.result;
              self.pathImage = data.path
              self.sendPost()
            })
            .catch((err) => {
              const data = err.response.data;
              if (data && data.message) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        self.sendPost()
      }
    },
    deletePost() {
      const self = this;
      api.posts
          .deletePost(this.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              utils.notificate(self, data.message);
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      this.$router.go(-2)
    },
    hideModal() {
      this.$refs['ref-confirm-delete-post'].hide()
    },

    clearPost() {
      this.getPost(this.id)
      utils.notificate(this, 'Изменения отменены')
    },
    resetImage() {
      this.postFile = null
      this.post.picture = "/static/images/no_photo.png"
    },
    onFileChange(e) {
      this.createImage(e);
    },
    createImage(file) {
      if (file != null){
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
  computed: {
    startStatus() {
      if (this.post.status === 'PUBLISHED') {
        return this.statusOption[0]
      } else if (this.post.status === '') {
        return this.statusOption[1]
      } else
        return this.statusOption[2]
    },
  },
  data() {
    return {
      id: '',
      sidebar: {
        categories: [],
      },
      image: '',
      pathImage: '',
      post: {
        author: {
          avatar: ''
        },
      },
      postEdit: {},
      postFile: null,
      categoryOption: ['Обновления', 'Новости компании'],
      statusOption: [
        {title: 'Опубликовано', value: 'PUBLISHED'},
        {title: 'Не опубликовано', value: ''},
        {title: 'На модерации', value: 'DRAFT'},
      ],
      snowOption: {
        theme: 'snow',
        placeholder: 'Ведите текст',
        modules: {
          toolbar: [
            'bold',
            'italic',
            'underline',
            {'align': []},
            'link',
            {'header': [1, 2, 3, 4, 5, 6, false]},
          ],
        },
      },
    }
  },
  mounted() {
    this.getCategories()
    let id = this.$router.currentRoute.params.id
    this.getPost(id)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
